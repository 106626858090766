import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';

import setCustomValidity from 'helpers/setCustomValidity';

const styles = {
  inputContainer: {
    marginTop: '25px',
    position: 'relative',
  },
  label: {
    color: '#999',
    fontWeight: 400,
    left: '15px',
    lineHeight: '39px',
    pointerEvents: 'none',
    position: 'absolute',
    top: '5px',
    order: 3,
    zIndex: 3,
  },
  labelFocused: {
    color: 'black',
    top: '-40px',
    left: 0,
  },
  labelInvalid: {
    fontWeight: 'bold',
  },
  input: {
    boxShadow: 'none',
    color: '#000',
    flex: '1 1 auto',
    lineHeight: 1.5,
    order: 2,
  },
  inputFocused: {
    boxShadow: 'none',
    fontWeight: 500,
    outline: 0,
    zIndex: 2,
  },
  inputBar: {
    backgroundColor: '#7F2924',
    display: 'inline-block',
    height: '4px',
    marginTop: '-4px',
    position: 'absolute',
    bottom: 0,
    left: 0,
    order: 3,
    zIndex: 3,
    transform: 'scale(0, 1)',
    width: '100%',
  },
  inputBarFocused: {
    transform: 'scale(1)',
  },
  inputBarInvalid: {},
  errorMessage: {
    bottom: 0,
    display: 'none',
    fontSize: '13px',
    overflow: 'hidden',
    position: 'absolute',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    textAlign: 'center',
    wordBreak: 'break-all',
    wordWrap: 'break-word',
    fontWeight: 'bold',
    order: 3,
    zIndex: 3,
  },
  errorMessageShow: {
    display: 'inline-block',
  },
};

/* eslint-disable react/no-multi-comp */
/* eslint-disable react/forbid-prop-types */

class Input extends React.Component {
  render() {
    return <input {...this.props} />;
  }
}

class FloatingLabel extends React.Component {
  static propTypes = {
    autoComplete: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    containerClass: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    containerAdditionalStyles: PropTypes.object,
    errorColor: PropTypes.string,
    errorMessage: PropTypes.string,
    errorMessageAdditionalStyles: PropTypes.object,
    id: PropTypes.string,
    inputAdditionalStyles: PropTypes.object,
    inputClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    inputBarAdditionalStyles: PropTypes.object,
    inputProps: PropTypes.object,
    inputRef: PropTypes.func,
    isDisabled: PropTypes.bool,
    isFocused: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    isRequired: PropTypes.bool,
    labelAdditionalStyles: PropTypes.object,
    labelFocusedAdditionalStyles: PropTypes.object,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onInvalid: PropTypes.func,
    pattern: PropTypes.any,
    placeholder: PropTypes.string.isRequired,
    tag: PropTypes.any,
    transition: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    valueGetter: PropTypes.func,
  };

  static defaultProps = {
    autoComplete: true,
    containerClass: 'input-group',
    containerAdditionalStyles: {},
    children: null,
    errorColor: '#FD7D78',
    errorMessage: '',
    errorMessageAdditionalStyles: {},
    id: 'floating-label',
    inputAdditionalStyles: {},
    inputClasses: {},
    inputBarAdditionalStyles: {},
    inputProps: {},
    inputRef: () => {},
    isDisabled: false,
    isFocused: null,
    isReadOnly: false,
    isRequired: false,
    labelAdditionalStyles: {},
    labelFocusedAdditionalStyles: {},
    name: 'floating-label',
    onBlur: () => {},
    onChange: () => {},
    onFocus: () => {},
    onInvalid: null,
    pattern: /.*/,
    tag: Input,
    transition: '0.4s ease all',
    type: 'text',
    value: null,
    valueGetter: event => event.currentTarget.value,
  };

  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
      hasValue: false,
      hasError: false,
      initialRender: true,
    };

    setTimeout(() => {
      this.setState({
        hasValue: Boolean(props.value),
        isFocused: props.isFocused || false,
      });
    });
    _.bindAll(this, 'onFocus', 'onBlur', 'onChange', 'onInvalid');
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (state.initialRender) {
      return { initialRender: false };
    }
    const newState = {
      hasValue: Boolean(nextProps.value),
    };
    if (nextProps.isFocused !== null) {
      newState.isFocused = nextProps.isFocused;
    }
    return newState;
  }

  onFocus(event) {
    this.setState({
      isFocused: true,
    });
    this.props.onFocus(event);
  }

  onBlur(event) {
    const { pattern, valueGetter } = this.props;
    const value = valueGetter(event);
    this.setState({
      isFocused: false,
      hasValue: Boolean(value),
      hasError: !pattern.test(value),
    });
    this.props.onBlur(event);
  }

  onChange(event) {
    const { valueGetter } = this.props;
    this.setState({
      hasValue: Boolean(valueGetter(event)),
      hasError: false,
    });
    setCustomValidity(this.ref, '');
    this.props.onChange(event);
  }

  onInvalid() {
    if (this.props.errorMessage) {
      setCustomValidity(this.ref, this.props.errorMessage);
    }
  }

  render() {
    const {
      autoComplete,
      children,
      containerClass,
      containerAdditionalStyles,
      errorColor,
      errorMessage,
      errorMessageAdditionalStyles,
      id,
      inputAdditionalStyles,
      inputClasses,
      inputBarAdditionalStyles,
      inputProps,
      inputRef,
      isDisabled,
      isReadOnly,
      isRequired,
      labelAdditionalStyles,
      labelFocusedAdditionalStyles,
      name,
      onInvalid,
      pattern,
      placeholder,
      transition,
      type,
      value,
    } = this.props;
    const { hasError, hasValue, isFocused } = this.state;
    const isFloating = hasValue || isFocused;

    const valueObj = {};
    if (value !== null) {
      valueObj.value = value;
    }

    const containerStyles = _.extend(
      {},
      styles.inputContainer,
      containerAdditionalStyles,
    );

    const inputStyles = _.extend(
      {},
      styles.input,
      inputAdditionalStyles,
      isFloating ? styles.inputFocused : {},
    );

    const labelStyles = _.extend(
      {},
      styles.label,
      labelAdditionalStyles,
      isFloating ? styles.labelFocused : {},
      isFloating ? labelFocusedAdditionalStyles : {},
      hasValue && hasError ? styles.labelInvalid : {},
      hasValue && hasError ? { color: errorColor } : {},
      { transition },
    );

    const inputBarStyles = _.extend(
      {},
      styles.inputBar,
      inputBarAdditionalStyles,
      isFocused ? styles.inputBarFocused : {},
      hasValue && hasError ? styles.inputBarInvalid : {},
      hasValue && hasError ? { backgroundColor: errorColor } : {},
      { transition },
    );

    const errorMessageStyles = _.extend(
      {},
      styles.errorMessage,
      errorMessageAdditionalStyles,
      { color: errorColor },
      hasValue && hasError ? styles.errorMessageShow : {},
    );

    return (
      <div className={containerClass} style={containerStyles}>
        {children}
        <this.props.tag
          {...inputProps}
          ref={ref => {
            this.ref = ref;
            inputRef(ref);
          }}
          autoComplete={autoComplete.toString()}
          className={cn(['form-control', inputClasses])}
          style={inputStyles}
          disabled={isDisabled}
          readOnly={isReadOnly}
          required={isRequired}
          id={id}
          name={name}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onInvalid={onInvalid || this.onInvalid}
          type={type}
          pattern={pattern.source}
          {...valueObj}
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label style={labelStyles} htmlFor={id}>
          {placeholder}
        </label>
        <span style={inputBarStyles} />
        {errorMessage && <span style={errorMessageStyles}>{errorMessage}</span>}
      </div>
    );
  }
}

export default FloatingLabel;

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import Parse from 'parse';
import classNames from 'classnames';
import FontAwesome from 'react-fontawesome';
import FAStyles from 'styles/global/font-awesome.css';
import withStyles from 'isomorphic-style-loader/withStyles';

import FloatingLabel from 'components/FloatingLabel';
import Link from 'components/Link';
import LoadingButton from 'components/LoadingButton';
import history from 'core/history';
import { ErrorAlert } from 'helpers/alert';
import { loginUser } from 'actions/user';
import handleParseError from 'helpers/handleParseError';

import s from './Login.scss';

class Login extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    loginUser: PropTypes.func.isRequired,
    next: PropTypes.string,
  };

  static defaultProps = {
    next: null,
  };

  constructor(props) {
    super(props);
    this.state = { email: '', password: '', isLoading: false };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { name } = event.target;
    const newState = {};
    newState[name] = event.target.value;
    this.setState(newState);
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.email === '' || this.state.password === '') {
      ErrorAlert('Please complete the form');
      return;
    }
    this.setState({ isLoading: true });
    Parse.User.logIn(this.state.email.toLowerCase(), this.state.password)
      .then(user => {
        this.props.loginUser(user);
        return new Promise(resolve => {
          setTimeout(resolve, 300);
        });
      })
      .then(() => {
        if (this.props.next) {
          history.push(decodeURIComponent(this.props.next));
        } else {
          history.push('/');
        }
      })
      .catch(
        handleParseError(error => {
          ErrorAlert(error.message);
        }),
      )
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  /* eslint-disable css-modules/no-undef-class */
  render() {
    return (
      <div className={classNames([s.root, 'jumbotron', 'text-center'])}>
        <div className={s.container}>
          <h1>{this.props.title}</h1>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Form onSubmit={this.handleSubmit} method="post">
                <FormGroup>
                  <Row>
                    <Col sm={12}>
                      <Col sm={12}>
                        <FloatingLabel
                          placeholder="Email"
                          id="email"
                          name="email"
                          type="email"
                          value={this.state.email}
                          inputAdditionalStyles={{
                            border: 0,
                            borderTopRightRadius: '5px',
                            borderBottomRightRadius: '5px',
                          }}
                          labelAdditionalStyles={{ left: '18%' }}
                          labelFocusedAdditionalStyles={{ color: 'white' }}
                          onChange={this.handleChange}
                          errorMessage="Please enter a valid email."
                          isRequired
                        >
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <FontAwesome name="user" className="fa-fw" />
                            </div>
                          </div>
                        </FloatingLabel>
                      </Col>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col sm={12}>
                      <Col sm={12}>
                        <FloatingLabel
                          placeholder="Password"
                          type="password"
                          name="password"
                          id="password"
                          value={this.state.password}
                          inputAdditionalStyles={{
                            border: 0,
                            borderTopRightRadius: '5px',
                            borderBottomRightRadius: '5px',
                          }}
                          labelAdditionalStyles={{ left: '18%' }}
                          labelFocusedAdditionalStyles={{ color: 'white' }}
                          onChange={this.handleChange}
                          isRequired
                        >
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <FontAwesome name="lock" className="fa-fw" />
                            </div>
                          </div>
                        </FloatingLabel>
                      </Col>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <small>
                    <Link className={s.brand} to="/forgotPassword">
                      Forgot your password? Click here to restore your password.
                    </Link>
                  </small>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col sm={12}>
                      <Col sm={12}>
                        <LoadingButton
                          isLoading={this.state.isLoading}
                          buttonText="Login"
                          buttonLoadingText="Login"
                          type="submit"
                          className="btn-primary-dark"
                          block
                        />
                      </Col>
                    </Col>
                  </Row>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  loginUser,
};

export default connect(
  null,
  mapDispatchToProps,
)(withStyles(FAStyles, s)(Login));

import React from 'react';
import _ from 'lodash';
import Login from './Login';

const title = 'Log In';

export default {
  path: '/login',

  action({ store, query }) {
    const { auth } = store.getState();
    // If user is logged in already, go home
    if (auth.isAuth) {
      return { redirect: '/' };
    }
    let next;
    if (_.has(query, 'next')) {
      next = query.next;
    }
    return {
      title,
      chunks: ['login'],
      component: <Login title={title} next={next} />,
    };
  },
};
